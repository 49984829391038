.moduleList:hover {
  cursor: pointer;
}

.moduleListItem:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.cursor:hover {
  cursor: pointer;
}

.mux-player {
  --media-object-fit: cover;
  border-radius: 20px;
}

.moduleHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

paper-button {
  text-transform: none !important;
}

.dropzone {
  border: 2px solid white;
  border-width: 1px;
  border-radius: 10px;
  background-color: transparent;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
}

textarea {
  font-family: sans-serif;
  padding: 2px;
}

input {
  padding: 2px;
}
