/* This will target all scrollable elements on your page */
::-webkit-scrollbar {
  width: 0px; /* Adjust width for vertical scrollbars */
  height: 0px; /* Adjust height for horizontal scrollbars */
}

/* The button on the scrollbar (up and down arrows - only appears under certain conditions on Windows) */
::-webkit-scrollbar-button {
  display: none;
}

/* The draggable scrolling handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px; /* roundness of the scrolling handle */
}

/* The track of the scrollbar */
::-webkit-scrollbar-track {
  background: transparent;
}
