@media (max-width: 768px) {
  .hideIfSmall {
    display: none;
  }
}

.inputField {
  color: white;
  padding: 10px;
  border: 1px solid #ded2d9;
  border-radius: 5px;
  background-color: transparent;
}

.inputField::placeholder {
  color: #fff;
  opacity: 1;
  font-family: sans-serif;
}
